<template>
  <div id="ctf">
    <div class="table" ref="tableRef">
      <a-table
        :data-source="tableList"
        size="small"
        :columns="columns"
        :rowKey="(record) => record.id"
        class="gray-table"
        :rowClassName="
          (record, index) => (index / 1 === index ? 'table-striped' : null)
        "
        :pagination="false"
        :scroll="{ x: tableWidth }"
      >
      </a-table>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, nextTick, onMounted } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";

export default defineComponent({
  name: "MoreAssignment",
  props: {
    userId: String,
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const columns = [
      {
        title: "Task Type",
        dataIndex: "taskType",
        ellipsis: true,
        width: 20,
      },
      {
        title: "Task Detail",
        dataIndex: "taskDetail",
        ellipsis: true,
        width: 20,
      },
      {
        title: "Start Date",
        dataIndex: "beginDate",
        ellipsis: true,
        width: 20,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        ellipsis: true,
        width: 20,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hours",
        ellipsis: true,
        width: 20,
      },
    ];
    const tableList = ref([]);
    const getTableList = () => {
      const data = {
        userId: props.userId,
      };
      $api.getLatestThreeTasks(data).then((res) => {
        tableList.value = res.data.resource;
      });
    };

    const tableWidth = ref(0);
    const tableRef = ref();

    const getSize = () => {
      nextTick(() => {
        tableWidth.value = tableRef.value.offsetWidth;
      });
    };

    onMounted(() => {
      getSize();
      getTableList();
    });

    return {
      columns,
      tableList,
      tableWidth,
      tableRef,
    };
  },
});
</script>
<style lang="less" scoped>
.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

#ctf {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  //width: 1850px;
  margin-left: 15px;

  .table {
    margin-top: 30px;
    .edit {
      width: 20px;
      cursor: pointer;
    }
  }
}
</style>
