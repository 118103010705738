<template>
  <div ref="empInfoRef">
    <a-drawer
      :getContainer="getRefsAff"
      :visible="isShowingEmpInfo"
      width="64.6vw"
      @close="cancel"
      :bodyStyle="{ overflow: 'hidden', padding: '0px' }"
      :title="title ? title : '人员详情'"
    >
      <div id="center">
        <div class="base-info-row">
          <div class="base-info">
            <userBasicInfo
              :userData="userData"
              :is-self="false"
              :user-id="getId"
            ></userBasicInfo>
            <a-button
              class="add-btn"
              v-if="isHaveAdd"
              @click="addEmp"
              type="primary"
              v-auth="['system:staffInfo:addEmp']"
            >
              添加至匹配器
            </a-button>
            <!-- <a @click="downloadCV" class="download-btn"> 简历下载 </a> -->
          </div>
        </div>
        <div class="part">
          <div class="part-title">简历管理</div>
          <resumeManagement
            :user-id="getId"
            @downloadCV="downloadCV"
          />
        </div>
        <div class="part">
          <div class="part-title">更多调令</div>
          <MoreAssignment :user-id="getId" />
        </div>
        <div class="part">
          <div class="part-title">更多任务</div>
          <MoreTask :user-id="getId" />
        </div>
        <div class="part">
          <div class="part-title">更多匹配</div>
          <MoreMatch :user-id="getId" />
        </div>
        <div class="part">
          <div class="part-title">技能</div>
          <staffingskill
            :skillDept="userData.skillDept"
            :is-self="source==='isShow'?true:false"
            :user-id="getId"
          />
        </div>
        <div class="part">
          <div class="part-title">证书</div>
          <Certification :is-self="false" :user-id="getId"></Certification>
        </div>
        <div class="part">
          <div class="part-title">项目经历</div>
          <work-experience
            :is-self="false"
            :user-id="getId"
            :show-work-experience-col="showworkExperienceCol"
          />
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import { useGlobalPropertyHook } from "@/hooks/common";
import { reactive, toRefs } from "vue";
import WorkExperience from "@/views/home/UserInfo/components/WorkExperience";
import userBasicInfo from "@/views/home/UserInfo/components/UserBasicInfo";
import staffingskill from "@/views/home/UserInfo/components/StaffingSkill";
import Certification from "@/views/home/UserInfo/components/Certification";
import MoreAssignment from "@/views/home/UserInfo/components/MoreAssignment";
import MoreTask from "@/views/home/UserInfo/components/MoreTask";
import MoreMatch from "@/views/home/UserInfo/components/MoreMatch";
import resumeManagement from "./resumeManagement.vue";
import { message } from "ant-design-vue";
import { downloadFile } from "@/utils/common";
export default defineComponent({
  components: {
    MoreMatch,
    MoreTask,
    MoreAssignment,
    WorkExperience,
    userBasicInfo,
    staffingskill,
    Certification,
    resumeManagement,
  },
  props: {
    isShowingEmpInfo: Boolean,
    getId: [Number, String],
    getIndex: Number,
    title: String,
    showworkExperienceCol: Boolean,
    isHaveAdd: {
      type: Boolean,
      default: false,
    },
    userDataList: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["close-emp-info", "add-user"],
  setup(props, { emit }) {
    const cancel = () => {
      console.log('关闭')
      emit("close-emp-info");
    };
    const addEmp = () => {
      emit("add-user", props.getIndex);
      cancel();
    };

    const { $api } = useGlobalPropertyHook();
    const state = reactive({
      userData: {},
    });

    const getUserData = () => {
      $api.getOneUser(props.getId).then((res) => {
        state.userData = res.data;
      });
    };

    const init = () => {
      // 有的父组件传了userDataList有的没传，导致可能调了两次getOneUser接口，以下进行区分
      if(JSON.stringify(props.userDataList) !== "{}" && props.userDataList) {
        state.userData = props.userDataList
      }else {
        getUserData();
      }
    };

    const downloadCV = () => {
      $api.getZipCV(props.getId).then((res) => {
        if (res.file.type == "application/json") {
          message.error("此人没有简历！");
        } else {
          const fileName = state.userData.fullName + "_简历.zip";
          downloadFile(res.file, fileName);
        }
      });
    };

    init();

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    return {
      ...toRefs(state),
      addEmp,
      cancel,
      downloadCV,
      getRefsAff
    };
  },
});
</script>

<style lang="less" scoped>
:deep(.ant-drawer .ant-drawer-content){
  padding-bottom: 0 !important;
}
#center {
  padding: 16px 16px 38px;
  width: 100%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }

  .base-info-row {
    display: flex;

    .base-info {
      flex: 1;
      margin-top: 50px;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 30px 30px 40px;
      position: relative;

      .upload-btn{
        position: absolute;
        top: 24px;
        right: 100px;
      }
      .download-btn {
        position: absolute;
        top: 24px;
        right: 24px;
      }

      .add-btn {
        position: absolute;
        top: 24px;
        left: 24px;
      }
    }

    .resume-part {
      width: 25%;
      margin-left: 18px;
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      padding: 20px 20px 40px;
    }

    .btn-part {
      margin-top: 50px;
      width: 20%;
      margin-left: 18px;
      display: flex;
      flex-direction: column;

      .ant-button {
        width: 100%;
      }
    }
  }

  .part {
    margin-top: 16px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px 25px 40px;
  }

  .part-title {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #2d3748;
    margin-left: 20px;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 26px;
      position: absolute;
      left: -20px;
      top: 2px;
      background: #3182ce;
      border-radius: 10px;
    }
  }
}
:deep(.ant-empty) {
  display: block !important;
}
</style>
