<template>
  <div id="ctf">
    <div class="table" ref="tableRef">
<!--      :scroll="{ x: tableWidth, }"-->
      <a-table
        :data-source="tableList"
        size="small"
        :columns="columns"
        :rowKey="(record) => record.id"
        class="gray-table"
        :rowClassName="
          (record, index) => (index / 1 === index ? 'table-striped' : null)
        "
        :pagination="false"
        :scroll="{ x: 1170 }"
        :expandIconColumnIndex="-1"
        :expandIconAsCell="false"
        :expandedRowKeys="curExpandedRowKeys"
      >
        <template #drop>
          <span style="color: #1890FF">●</span>
        </template>
        <template #type>
          <span>主</span>
        </template>
        <template #RowRender="{ record }">
          <div v-if="record.staffAssignmentParts.length > 0">
          <span @click="segmentedEditingClick(record.id)" style="cursor: pointer;font-size: 0.7vw;">
            <span v-if="curExpandedRowKeys.includes(record.id)"> <DownOutlined /> </span>
            <span v-else> <RightOutlined /> </span>
          </span>
          </div>
        </template>
        <template #expandedRowRender="{ record }">
          <a-table
              :data-source="record.staffAssignmentParts"
              size="small"
              :columns="staffcolumns"
              rowKey="startDate"
              :pagination="false"
              :scroll="{ x: 1170 }"
              :showHeader="false"
              class="staffClass"
          >
            <template #drop>
              <span style="color: #77CAED">●</span>
            </template>
            <template #type>
              <span>分</span>
            </template>
            <template #engagementCode>
              <span>{{record.engagementCode}}</span>
            </template>
            <template #engagementDescription>
              <span>{{record.engagementDescription}}</span>
            </template>
            <template #actualReleaseDate>
              <span>{{record.actualReleaseDate}}</span>
            </template>
          </a-table>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { DownOutlined, RightOutlined} from "@ant-design/icons-vue";

export default defineComponent({
  name: "MoreAssignment",
  props: {
    userId: String,
  },
  components: {
    RightOutlined,
    DownOutlined,
  },
  setup(props) {
    const { $api } = useGlobalPropertyHook();
    const columns = [
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: 40,
        slots: { customRender: "RowRender" },
      },
      {
        title: "",
        dataIndex: "",
        ellipsis: true,
        width: 40,
        slots: { customRender: "drop" },
      },
      {
        title: "Code",
        dataIndex: "engagementCode",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Description",
        dataIndex: "engagementDescription",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Assignment Segmentation Type",
        ellipsis: true,
        width: 220,
        slots: { customRender: "type" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "onBoardDate",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "segmentationEndDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Actual Release Date",
        dataIndex: "actualReleaseDate",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        ellipsis: true,
        width: 200,
      },
    ];
    const tableList = ref([]);
    const getTableList = () => {
      const data = {
        userId: props.userId,
      };
      $api.getLatestThreeAssignments(data).then((res) => {
        tableList.value = res.data.resource
        // tableList.value.staffAssignmentParts = tableList.value.staffAssignmentParts.map(item => {
        //   return {
        //     id: item.id,
        //     endDate: item.endDate,
        //     hoursPerDay: item.hoursPerDay,
        //     startDate: item.startDate,
        //     engagementCode: item.engagementCode,
        //     engagementDescription: item.engagementDescription,
        //   }
        // })
      });
    };

    // const tableWidth = ref(0);
    const tableRef = ref();

    // const getSize = () => {
    //   nextTick(() => {
    //     tableWidth.value = tableRef.value.offsetWidth;
    //   });
    // };

    const curExpandedRowKeys = ref([])
    const segmentedEditingClick = (rowkey) => {
      if (curExpandedRowKeys.value.length > 0) {
        let index = curExpandedRowKeys.value.indexOf(rowkey);
        if (index > -1) {
          curExpandedRowKeys.value.splice(index, 1);
        } else {
          curExpandedRowKeys.value.splice(0, curExpandedRowKeys.value.length);
          curExpandedRowKeys.value.push(rowkey);
        }
      } else {
        curExpandedRowKeys.value.push(rowkey);
      }
    }

    const staffcolumns = [
      {
        title: "",
        dataIndex: "",
        key: "",
        width: 40,
        //slots: { customRender: "RowRender" },
      },
      {
        title: "",
        dataIndex: "",
        ellipsis: true,
        width: 40,
        slots: { customRender: "drop" },
      },
      {
        title: "Code",
        ellipsis: true,
        width: 200,
        slots: { customRender: "engagementCode" },
      },
      {
        title: "Description",
        ellipsis: true,
        width: 200,
        slots: { customRender: "engagementDescription" },
      },
      {
        title: "Assignment Segmentation Type",
        dataIndex: "",
        ellipsis: true,
        width: 220,
        slots: { customRender: "type" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "startDate",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "endDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Actual Release Date",
        ellipsis: true,
        width: 250,
        slots: { customRender: "actualReleaseDate" },
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        ellipsis: true,
        width: 200,
      },
    ]

    onMounted(() => {
      // getSize();
      getTableList();
    });

    return {
      columns,
      tableList,
      // tableWidth,
      tableRef,
      curExpandedRowKeys,
      segmentedEditingClick,
      staffcolumns,
    };
  },
});
</script>
<style lang="less" scoped>
.ant-modal-header {
  height: 80px;
  line-height: 80px;
}

#ctf {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  //width: 1850px;
  margin-left: 15px;

  .table {
    margin-top: 30px;
    .edit {
      width: 20px;
      cursor: pointer;
    }
  }
}
:deep(.ant-table-row-expand-icon) {
  border: 0;
}
.staffClass {
  :deep(.ant-table .ant-table-tbody > tr > td){
    border-bottom: 0;
    padding: 0.2vw 0.41667vw !important;
  }
  :deep(.ant-table .ant-table-tbody){
    background: #FCFCFC;
  }
  //border-bottom: 1px solid #f0f0f0;
}
</style>
